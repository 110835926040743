import Link from 'next/link'
import { AnchorHTMLAttributes } from 'react'
import { styled } from 'styled-components'

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
	children: JSX.Element | string
}

const TextLink = ({ children, href, ...props }: Props) => {
	return (
		<TextLinkComponent href={href ?? '#'} {...props}>
			{children}
		</TextLinkComponent>
	)
}

const TextLinkComponent = styled(Link)`
	color: ${({ theme }) => theme.style.colorCta};
`
export default TextLink
