import IconSelector from '@components/IconSelector'
import DoughnutChart from '@components/charts/DoughnutChart'
import { LazyColorPicker } from '@functions/ColorpickerFunction'
import { device } from '@helpers/DeviceHelper'
import { useAppSelector } from '@hooks/ReduxStore'
import useDidMountEffect from '@hooks/UseDidMountEffect'
import CompositionDtoModel from '@models/dto/StatsDtoModel'
import ChartDataModel from '@models/internal/ChartDataModel'
import { getCompositionState } from '@store/ExchangeSlice'
import { useEffect, useState } from 'react'
import { styled } from 'styled-components'

const VaultGraph = () => {
	const basket = useAppSelector<CompositionDtoModel[] | undefined>(
		getCompositionState
	)

	const [allocationList, setAllocationList] = useState<ChartDataModel[]>([])
	const [legenda, setLegenda] = useState<ChartDataModel[]>([])

	useEffect(() => {
		if (basket && basket.length > 0) {
			const list: ChartDataModel[] = basket
				.filter(({ label }) => label !== undefined)
				.map(({ label, value, name }) => {
					return {
						name: name,
						label: label,
						data: value
					}
				})

			setAllocationList(list.filter((item) => item.data > 0))
		}
	}, [basket])

	useDidMountEffect(() => {
		const total = allocationList.reduce((prev, { data }) => prev + data, 0)

		const list = allocationList.map(({ name, label, data }) => {
			return { name: name, label: label, data: (data / total) * 100 }
		})

		setLegenda(list)
	}, [allocationList])

	return (
		<Container>
			<Chart>
				<DoughnutChart data={allocationList} />
			</Chart>
			<ul>
				{legenda.map(({ label, name, data }, index) => (
					<Info key={index}>
						{/* <Label $color={Colorpicker(label)} /> */}
						<Label $color={LazyColorPicker[index]}>
							{IconSelector({ name: name ?? '' })}
						</Label>
						<span>{label}</span>
						<Percentage>{data}%</Percentage>
					</Info>
				))}
			</ul>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 4em;
	& > * {
		flex: 1 1 auto;
	}

	flex-direction: column;

	@media ${device.laptop} {
		flex-direction: row;
	}
`
const Info = styled.li`
	display: flex;
	justify-content: space-between;
	gap: 1em;
	padding: 0.5em 0;

	& > * {
		flex: 1 1 auto;
	}
`
const Label = styled.div<{ $color: string }>`
	width: 2em;
	height: 2em;
	border-radius: 50%;
	background-color: ${({ $color }) => $color};
	padding: 0.25em;
	flex: 0 0 auto;
	display: flex;
`
const Percentage = styled.span`
	text-align: right;
`
const Chart = styled.div`
	flex: 0 1 auto;
	max-width: 12em;

	align-self: center;

	@media ${device.laptop} {
		align: flex-start;
	}
`
export default VaultGraph
