import { FormikProps } from 'formik'
import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label: string | JSX.Element
	inputName: string
	formikProps: FormikProps<any>
}

const CheckboxInputField = ({
	label,
	inputName,
	formikProps,
	...props
}: Props) => {
	return (
		<Label htmlFor={props.id} $readOnly={props.readOnly}>
			<Container>
				<Radio
					type="checkbox"
					name={inputName}
					onChange={formikProps.handleChange}
					onBlur={formikProps.handleBlur}
					value={
						formikProps.values[inputName] !== 0
							? formikProps.values[inputName]
							: ''
					}
					{...props}
				/>
			</Container>

			<Title $disabled={props.disabled || props.readOnly}>{label}</Title>
		</Label>
	)
}

const Label = styled.label<{ $readOnly?: boolean }>`
	display: flex;
	align-items: center;
	gap: 0.25em;
	margin: 1em 0;
	${({ $readOnly }) =>
		$readOnly &&
		`
        opacity: 0.75;
        pointer-events: none;
    `}
`
const Container = styled.div`
	display: inline-block;
	vertical-align: middle;
`
const Radio = styled.input`
	display: block;
	width: 1.25em;
	height: 1.25em;
	float: left;
`
const Title = styled.div<{ $disabled?: boolean }>`
	vertical-align: middle;
	margin-left: 0.5em;
	color: ${({ $disabled, theme }) =>
		$disabled ? theme.style.colorDisabled : 'inherit'};
`

export default CheckboxInputField
